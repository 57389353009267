@import "@/index.scss";

.p-users-container {
  width: 100%;
  overflow: auto;

  @include media-breakpoint-down(md) {
    padding-bottom: 200px;
  }

  .kpi-part {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content;
    gap: 20px;
    padding: 20px;
    @include media-breakpoint-down(sm) {
      padding-right: 0px;
    }

    .kpi-card {
      height: fit-content;
      width: 190px;
      border-radius: 5px;
      background: var(--ui-white);
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 15px;
      box-shadow: 0px 0px 20px #ededed;
      transition: all ease-in-out 300ms;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 20px #ededed;
      }

      .value {
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        color: var(--ui-primary);
        font-size: x-large;
        text-transform: none;
      }

      .text {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        color: var(--ui-gray);
        font-size: small;
        text-transform: none;
        white-space: nowrap;
      }

      .percentage {
        font-family: "Open Sans", sans-serif;
        font-weight: bold;
        color: var(--ui-primary);
        font-size: x-small;
        text-transform: none;
        position: absolute;
        top: 20px;
        right: 20px;
      }

      .mini-pie {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        height: 30px;
        width: 30px;
      }

      &.modif {
        background: rgba(
          $color: #444242,
          $alpha: 0.2
        ); // var(--ui-primary-tint);
        span {
          color: var(--ui-medium);
        }
        &.active {
          background: var(--ui-medium);
          span {
            color: var(--ui-light);
          }
        }
      }

      &.refuse {
        background: rgba(
          $color: #872d2d,
          $alpha: 0.2
        ); // var(--ui-primary-tint);
        border-left: 5px solid var(--ui-error);
        span {
          color: var(--ui-error);
        }
        &.active {
          background: var(--ui-error);
          span {
            color: var(--ui-light);
          }
        }
      }

      &.attente {
        background: rgba($color: #f5b544, $alpha: 0.2);
        border-left: 5px solid var(--ui-warning);
        span {
          color: var(--ui-secondary);
        }

        &.active {
          background: var(--ui-warning);
          span {
            color: var(--ui-light);
          }
        }
      }

      &.accepte {
        background: rgba($color: #006c69, $alpha: 0.2);
        border-left: 5px solid var(--ui-primary);
        span {
          color: var(--ui-primary);
        }

        &.active {
          background: var(--ui-primary);
          span {
            color: var(--ui-light);
          }
        }
      }
    }
  }

  .table-part {
    width: 100%;
    height: fit-content;
    margin-top: 20px;
    padding-left: 20px;

    &__table-footer {
      width: 100%;
      display: flex;
      align-items: center;
      height: fit-content;
      background: #ffffff;
      border-top: 2px solid #ededed;
      padding: 20px;
      gap: 10px;

      @include media-breakpoint-down(md) {
        height: unset;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
      }

      &__footer-indicator {
        color: var(--ui-medium);
        font-size: 0.8rem;
        font-weight: 400;
      }

      &__pagination {
        margin-left: auto;
        @include media-breakpoint-down(md) {
          margin: 0;
        }
      }
    }
  }
}
