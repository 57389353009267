@import "@/index.scss";

.p-members-container {
  width: 100%;
  overflow: auto;

  @include media-breakpoint-down(md) {
    padding-bottom: 200px;
  }

  .tool-part {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px;

    .tool-btn {
      height: fit-content;
      width: fit-content;
      font-size: x-small;
      padding: 10px;
      border-radius: 5px;
    }
  }

  .table-part {
    width: 100%;
    height: fit-content;
    margin-top: 20px;
    padding-left: 20px;

    &__table-footer {
      width: 100%;
      display: flex;
      align-items: center;
      height: fit-content;
      background: #ffffff;
      border-top: 2px solid #ededed;
      padding: 20px;
      gap: 10px;

      @include media-breakpoint-down(md) {
        height: unset;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
      }

      &__footer-indicator {
        color: var(--ui-medium);
        font-size: 0.8rem;
        font-weight: 400;
      }

      &__pagination {
        margin-left: auto;
        @include media-breakpoint-down(md) {
          margin: 0;
        }
      }
    }
  }
}
