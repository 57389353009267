@import '@/index.scss';

.dashboard-chart-card {
    width: 100%;
    height: fit-content;
    border-radius: 5px;
    background: var(--ui-white);
    box-shadow: 0px 0px 30px #ececec;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 20px;

    .title {
        font-weight: 400;
        color: var(--ui-gray);
        font-size: 1rem;
    }


    .chart-part {
        width: 100%;
        height: fit-content;
        margin-top: 20px;

        .loading-chart {
            width: 100%;
            height: 300px;
            border-radius: 10px;
            background: #fafafa;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                height: 80%;
                width: auto;
                border-radius: 10px;

                @include media-breakpoint-down(lg) {
                    height: auto;
                    width: 50%;
                }

            }
        }
    }

}