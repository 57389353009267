@import '@/index.scss';


.side-modal-container {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 35%;
    height: 100%;
    background: var(--ui-white);
    padding: 20px;

    @include media-breakpoint-down(xxl) {
        width: 45%;
    }

    @include media-breakpoint-down(lg) {
        width: 70%;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
    }


    .close-icon {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 3;
    }

    .scroll-modal-content {
        width: 100%;
        height: 100%;
    }

}