@import "@/index.scss";


.ct-dashboard {
    width: 100%;
    padding-top: 20px;
    overflow: auto;

    @include media-breakpoint-down(md) { 
        padding-bottom: 200px;
    }
    

    .kpi-part {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;


    }
}