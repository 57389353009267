@import '@/index.scss';

.signup-container{
    @include auth-form-layout();
    gap:30px;
    width: 70%;
    @include media-breakpoint-down(lg) {
        width: 70%;
    }
    @include media-breakpoint-down(md) {
        width: 70%;
    }
    @include media-breakpoint-down(sm) {
        width: calc( 100% - 20px);
    }
    //max-height: 70vh;
    overflow: auto;
    
    .header-part {
       @include vertical-display-layout (10px);
       width: 100%;
       align-items: center;
       margin-top: 30px;

       .title {
          font-size: var(--title-size);
          font-weight: normal;
          color: var(--ui-dark);
       }

       .subtitle {
          font-size: var(--small-size);
          color: var(--ui-gray);
       }
    }
 
    .form-part {
       @include vertical-display-layout (15px);
       width: 100%;
       align-items: center;

       .paiement-radio-row{
            background: var(--ui-light);
            border-radius: 10px;
            padding: 5px;

       }

    }
 
    .footer-part{
       @include vertical-display-layout (5px);
       width: 100%;
       align-items: center;
       font-size: var(--normal-size);

       .link-primary {
          color: var(--ui-primary);
          transition: color 300ms ease-in-out;

          &:hover{
             color: rgba(var(--ui-primary-rgb),0.7);
             cursor: pointer;
          }
       }
       
       .link-gray {
          color: var(--ui-gray);
          transition: color 300ms ease-in-out;
          font-size: var(--small-size);

          &:hover{
             color: rgba(var(--ui-gray-rgb),0.7);
             cursor: pointer;
          }
       }
    }
}