@import "@/index.scss";

.c-table-adherents-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  border-radius: 10px;
  background: var(--ui-white);
  box-shadow: 0px 0px 20px #ededed;

  &__table-option {
    width: 100%;
    display: flex;
    height: 70px;
    align-items: center;
    gap: 10px;
    padding: 20px 20px;
    position: relative;
      &::after {
        content: '';
        position: absolute;
        bottom: -50px;
        left: 0px;
        height: 50px;
        width: 100%;
        background: #ededed;
        z-index: 0;
      }

    @include media-breakpoint-down(md) {
      height: unset;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px;
    }

    .table-title{
        color: var(--ui-gray);
        font-size: 1rem;
        font-weight: 400;
    }

    .search-input {
        flex: 1;
        max-width: 20vw;
        height: fit-content;
        //width: 25vw;
        background: var(--ui-white);
        margin: 0 0 0 auto;
        @include media-breakpoint-down(lg) {
            width: 100%;
            max-width: unset;
        }

        .MuiOutlinedInput-root {
            height: 40px;
            font-size: small;
            font-weight: 400;
            @include media-breakpoint-down(lg) {
              height: 30px;
            }
        }
    }

    .option-btn {
      height: fit-content;
      width: fit-content;
      font-size: x-small;
    }
  }

  &__scroll-container{
    width: 100%;
    &__table-header {
      width: fit-content;
      display: flex;
      height: 50px;
      align-items: center;
      background: #ededed;
      gap: 2px;
      

      .header-item {
        height: 40px;
        width: 200px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding-left: 20px;
        font-weight: 600;
        font-size: small;
        color: var(--ui-medium);
        
        &:not(:first-child){
          position: relative;
          &::before{
              content: '';
              position: absolute;
              height: 60%;
              top: 50%;
              left: 0px;
              width: 2px;
              transform: translateY(-50%);
              background: var(--ui-light);
          }
        }
      }
    }

    &__table-body {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: fit-content;
      background: #ffffff;

      &__table-row {
        width: fit-content;
        display: flex;
        height: 70px;
        align-items: center;
        gap: 2px;
        background: var(--ui-white);
        
        &:nth-child(even) {
          background: var(--ui-light);
        }
  
        .row-item {
          height: fit-content;
          width: 200px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 20px;
          font-weight: 400;
          font-size: small;
          color: var(--ui-gray);
          gap: 10px;
          
          &:not(:first-child){
            position: relative;
            &::before{
                content: '';
                position: absolute;
                height: 70px;
                left: 0px;
                width: 2px;
                background: #ededed;
            }
          }

          .MuiListItemText-primary{
            width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: small;
          }

          .MuiListItemText-secondary{
            width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: small;
          }

          .MuiChip-root {
            width: 120px;
          }

          .icon-btn {
            box-shadow: 0px 0px 20px #d2d1d1;
          }

        }

      }
    }
  }

}
