@import '@/index.scss';

.c-header {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  height: 70px;
  background: #ffffff;
  //
  //visibility: hidden;//yel ooooh yel yel yel
  //

  @include media-breakpoint-down(md) {
    flex-direction: column;
    padding: 10px 0px;
    gap: 20px;
  }

  .mobile-header {
    display: none;
    position: relative;

    @include media-breakpoint-down(md) {
      height: 40px;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 10px;
    }

    .logo-mini {
      height: 70%;
      width: auto;
      transform: translateY(2px);
    }

    .title-part {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0px;

      .title {
        font-size: var(--small-size);
        font-weight: normal;
        color: var(--ui-tertiary-shade);
        @include text-ellipsis (150px);
      }

      .subtitle {
        font-size: xx-small;
        color: var(--ui-tertiary-shade);
        @include text-ellipsis (150px);
      }
    }

    .btn-moderation {
      margin-left: auto;
      height: 30px;
      width: 30px;
      min-width: unset;
      border-radius: 7px;
      padding: 0%;
    }

    .rounded-square-btn-head {
      height: 30px;
      width: 30px;
      border-radius: 7px;
      background: var(--ui-tertiary);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 200ms ease-in-out;

      &:hover {
        cursor: pointer;
        background: rgba(var(--ui-tertiary-rgb), 0.5);
      }
    }
  }

  .logo-mini-md {
    height: 50px;
    width: auto;
    margin-right: 10px;
    display: none;

    @include media-breakpoint-only(md) {
      display: block;
    }
  }

  .title-part {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;

    @include media-breakpoint-down(md) {
      width: 100%;
      display: none;
    }


    .title {
      font-size: var(--title-size);
      font-weight: 400;
      color: var(--ui-medium);
      //text-transform: capitalize;

      @include media-breakpoint-down(xl) {
        // @include text-ellipsis (200px);
      }

      @include media-breakpoint-down(lg) {
        // @include text-ellipsis (250px);
      }

    }

    .subtitle {
      font-size: var(--normal-size);
      color: var(--ui-tertiary-shade);
      color: var(--ui-medium);
      text-transform: uppercase;

      @include media-breakpoint-down(xl) {
        // @include text-ellipsis (200px);
      }

      @include media-breakpoint-down(lg) {
        // @include text-ellipsis (250px);
      }
    }
  }


  .btn-part {
    width: 200px;
    margin-left: auto;
    margin-right: 100px;

    @include media-breakpoint-down(xl) {
      margin-right: 0px;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      margin: unset;
      padding: 0px 20px;
      display: none;
    }

    @include media-breakpoint-down(sm) {
      padding: 0px;
    }

    .btn-moderation {
      border-radius: 50px;
      height: fit-content;
    }
  }


  .icon-header {
    margin-left: 10px;
    cursor: pointer;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .rounded-square-btn {
    height: 35px;
    width: 35px;
    border-radius: 10px;
    background: var(--ui-primary);
    margin-left: auto;
    // display: flex;
    align-items: center;
    justify-content: center;
    transition: all 200ms ease-in-out;
    display: none;

    @include media-breakpoint-down(lg) {
      display: flex;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }

    &:hover {
      cursor: pointer;
      background: rgba(var(--ui-tertiary-rgb), 0.5);
    }
  }

  .infos-user {
    width: fit-content;
    padding: 5px 10px;
    border-radius: 5px;
    background: var(--ui-white);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;

    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      gap: 5px;
      color: var(--ui-medium);
      text-transform: uppercase;
      margin-right: 20px;

      .fullname {
        font-size: var(--small-size);
        font-weight: bold;
      }

      .role {
        font-size: x-small;
        font-weight: 200;
      }

    }

    .icon-user {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      margin-right: 10px;
    }



    .icon-end {
      height: 5Ò0px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      cursor: pointer;

    }
  }


}


.menu-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0px;
  color: var(--ui-medium);
  text-transform: uppercase;

  .fullname {
      font-size: var(--small-size);
      font-weight: bold;
  }
  .role {
      font-size: x-small;
      font-weight: 200;
  }

}

.menu-nav-link{
  color: var(--ui-gray);
  font-weight: 200;
  height: 35px;
  padding: 0px 10px;
  width: 120px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: var(--small-size);

  &:hover {
    font-weight: 400;
  }

  &.selected {
    background: var(--ui-primary);
    color: var(--ui-light);
    font-weight: 400;
  }
} 
