@import '@/index.scss';

.c-bottombar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 70px;
    width: 100%;
    // background: var(--ui-primary) repeat url("../../../../assets/images/png/pattern.png");

    .mui-bottom-navigation{
            height: 100%;
            width: 100%;
            background: var(--ui-primary);

            .MuiButtonBase-root {
                border-radius: 30px;
                min-width: unset;
            }
    }
}