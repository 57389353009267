@import '@/index.scss';

.c-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 330px;
  background: var(--ui-primary);
  display: block;
  z-index: 3;

  @include media-breakpoint-down(lg) { 
    &.opened {
      display: block;
    }
  }

  @include media-breakpoint-up(lg) {
      display: block;
  } 

  .sidebar-top {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    height: fit-content;

    @include media-breakpoint-up(lg) {
      display: none;
    } 

    &__header-btn-container {
      height: 70px;
      width: 100%;
      background: rgba(var(--ui-dark-rgb), 0.3);
      padding: 10px;
      border-radius: 3px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;
      position: absolute;
      top: 0px;
      left: 0px;

      @include media-breakpoint-up(lg) { 
          display: none;
      }


      .rounded-square{
          height: 35px;
          width: 35px;
          border-radius: 10px;
          background: var(--ui-primary);
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 200ms ease-in-out;

          &:hover {
            cursor: pointer;
            background: rgba(var(--ui-secondary-rgb),0.5);
          }

          &:nth-child(2){
            margin-left: auto;
          }
      }
    }

    &__logo-part {

      width: 100%;
      display: flex;
      justify-content: center;
      position: relative;
      background: rgba(var(--ui-dark-rgb), 0.3);
      padding: 10px 0px;
      border-radius: 3px;
      margin-bottom: 40px;

        @include media-breakpoint-down(lg) { 
          display: none;
        }

        &::after{
          position: absolute;
          content: '';
          bottom: -15px;
          left: 50%;
          transform: translateX(-50%);
          width: 50px;
          height: 5px;
          border-radius: 3px;
          background: var(--ui-secondary);
        }

        &__logo {
          height: 30px;
          width: auto;
        }
    }


    &__avatar {
      margin-bottom: 20px;
      height: 50px;
      width: 50px;
      border-radius: 50%;

      @include media-breakpoint-down(lg) { 
          margin-top: 70px;
      }
    }

    &__username {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 1px;
      font-variant: small-caps;
      color: var(--ui-light);
      mix-blend-mode: normal;
      width: 100%;
    }

    &__identity_number {
      font-weight: 300;
      font-size: 10px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 1px;
      font-variant: small-caps;
      color: var(--ui-light);
      margin-bottom: 10px;
    }

    &__other-infos {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &__infos-bloc {
        display: flex;
        align-items: center;
        color: var(--ui-light);
        font-size: var(--subtitle-size);
        span{
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: 10px;
        }
      }
    }





  }

  .sidebar-top-for-lg {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    height: fit-content;

    &__logo-part {

      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;
      position: relative;
      background: var(--ui-light);
      padding: 0px 30px;
      height: 70px;
      margin-bottom: 50px;
      background: #ffffff;
      box-shadow: -22px 2px 20px #01352c;

      @include media-breakpoint-down(lg) { 
        display: none;
      }

      &__logo {
        width: auto;
        //display: none; // <- temporarly hide

        &:nth-child(1){
          height: 40px;
        }

        &:nth-child(2){
          height: 25px;
        }
      }
    }

    &__title {
      font-weight: 200;
      font-size: 18px;
      text-align: left;
      letter-spacing: 1px;
      color: var(--ui-light);
      padding-left: 30px;
      margin-bottom: 10px;
    }

  }

  .sidebar-menu {
    // display: flex;
    // flex-direction: column;
    overflow: auto;
    padding: 1rem 0;
    padding-left: 18px;
    height: calc(100% - var(--sidebar-top-height));

    @include media-breakpoint-down(lg) { 
      padding-right: 18px;
    }

    hr {
      width: calc(100% - 18px) ;
      margin: 40px 0px;
      border-color: rgba(var(--ui-light-rgb),0.3);
    }

  }

}
