@import '@/index.scss';

.adherent-form {
    width: 100%;
    display: flex;
    display: flex;
    flex-direction: column;
    background: var(--ui-white);
    padding-right: 20px;
    gap: 10px;

    .title {
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-size: large;
        color: var(--ui-dark);
        margin-bottom: 20px;
    }


    .sub-title-part {
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-size: small;
        color: var(--ui-primary);
        margin-top: 20px;
    }

    .custom-divider {
        margin-bottom: 20px;
        width: 100%;
        border-color: var(--ui-primary);
        border-width: 1px;
        border-style: dotted;

    }

    .my-text-field {

        .MuiOutlinedInput-root {
            height: 50px;
        }

        .MuiAutocomplete-input {
            height: 0.4rem;
        }

        .MuiInputLabel-root {
            font-size: normal;
            top: 50%;
            transform: translate(14px, -50%) scale(1);

            &.Mui-focused {
                top: 0px;
                transform: translate(14px, -9px) scale(0.75);
            }

            &.MuiFormLabel-filled {
                top: 0px;
                transform: translate(14px, -9px) scale(0.75);
            }
        }


        input {
            font-family: 'Open Sans', sans-serif;
            font-weight: normal;
            font-size: normal;
        }
    }

    .btn-part{
        width: 100%;
        height: fit-content;
        gap: 20px;
        display: flex;
        align-items: center;
        margin-top: 20px;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }

        .btn-option {
            height: 40px;
            border-radius: 5px;
            width: fit-content;
            text-transform: none;
            flex-wrap: nowrap;
    
            @include media-breakpoint-down(lg) {
                margin-right: 0px;
                height: 50px;
            }
    
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }

    }

}