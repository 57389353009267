@import '@/index.scss';

.signin-container {
   width: 90%;
   background-color: var(--ui-white);
   border-radius: 3px;
   display: flex;
   align-items: center;
   overflow: auto;
   border-top: 15px solid var(--ui-secondary);


   @include media-breakpoint-down(lg) {
      width: 80%;
      justify-content: center;
   }

   @include media-breakpoint-down(md) {
      width: 80%;
      justify-content: center;
   }

   @include media-breakpoint-down(sm) {
      width: calc(100% - 20px);
      justify-content: center;
   }


   .left-part {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;
      padding: 20px 50px 100px 50px;
      @include media-breakpoint-down(lg) {
         width: calc(100% - 20px);
         align-items: center;
      }
   
      @include media-breakpoint-down(md) {
         width: calc(100% - 20px);
         align-items: center;
      }
   
      @include media-breakpoint-down(sm) {
         width: calc(100% - 20px);
         align-items: center;
         padding: 10px;
      }

      .header-part {
         @include vertical-display-layout (10px);
         width: 100%;
         align-items: flex-start;
         margin: 10px 0px 30px 0px;

         .intro {
            font-size: var(--subtitle-size);
            color: var(--ui-gray);

            @include media-breakpoint-down(md) {
               text-align: center;
            }
         }

         .title {
            font-size: var(--subtitle-size);
            font-weight: normal;
            color: var(--ui-dark);
            margin-top: 15px;
         }

         @include media-breakpoint-down(md) {
            align-items: center;
         }

      }

      .form-part {
         @include vertical-display-layout (15px);
         width: 100%;
         align-items: flex-start;

         .MuiFormControlLabel-label {
            font-size: small;
            font-weight: 200;
         }

         @include media-breakpoint-down(sm) {
            align-items: center;
         }
      }

      .footer-part {
         @include vertical-display-layout (5px);
         width: 100%;
         align-items: center;
         font-size: var(--normal-size);

         .link-primary {
            color: var(--ui-primary);
            transition: color 300ms ease-in-out;

            &:hover {
               color: rgba(var(--ui-primary-rgb), 0.7);
               cursor: pointer;
            }
         }

         .link-gray {
            color: var(--ui-gray);
            transition: color 300ms ease-in-out;
            font-size: var(--small-size);

            &:hover {
               color: rgba(var(--ui-gray-rgb), 0.7);
               cursor: pointer;
            }
         }
      }

   }

   .right-part {
      width: 50%;
      height: 100%;
      background: var(--ui-light);
      padding: 20px 50px 100px 50px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 20px;
      position: relative;


      @include media-breakpoint-down(lg) {
         width: 50%;
      }
   
      @include media-breakpoint-down(md) {
         display: none;
      }
   
      @include media-breakpoint-down(sm) {
         display: none;
      }

      .title {
         font-size: var(--subtitle-size);
         font-weight: normal;
         color: var(--ui-dark);
         margin-top: 10px;
      }

      .bg-auth {
         width: 80%;
         height: auto;
         position: absolute;
         bottom: 20px;
         left: 50%;
         transform: translateX(-50%);
      }
   }
}