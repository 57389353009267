@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@mixin auth-form-layout() {
    width: 100%;
    background-color: var(--ui-white);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px;

    @include media-breakpoint-down(sm) {
        padding: 10px;
    }
}

@mixin vertical-display-layout($gap) {
    display: flex;
    flex-direction: column;
    gap: $gap;
}

@mixin text-ellipsis($width) {
    width: $width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.underlined {
    position: relative;
    &::after{
        content: '';
        position: absolute;
        bottom: -5px;
        left: 0px;
        height: 2px;
        width: 30px;
        border-radius: 2px;
        background: var(--ui-primary);

    }
    &::before{
        content: '';
        position: absolute;
        bottom: -5px;
        left: 45px;
        height: 2px;
        width: 85px;
        border-radius: 2px;
        background: var(--ui-primary);

    }
}

