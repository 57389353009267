@import '@/index.scss';

.main-column-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 10px;



    .chart-part {
        width: 100%;
        height: 30vh;
        border-radius: 10px;
        //box-shadow: 0px 0px 50px #ededed;
        padding: 20px;

        @include media-breakpoint-down(lg) {
            width: 1920px;
        }
    
        .recharts-yAxis {
            font-family: 'Open Sans', sans-serif ;
            font-size: var(--normal-size);
            font-weight: 600;
        }
    
        .recharts-xAxis {
            font-family: 'Open Sans', sans-serif ;
            font-size: var(--small-size);
            font-weight: 400;
    
            .recharts-text {
                transform: translateY(10px);
            }
        }

    }


}