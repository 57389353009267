@import "@/index.scss";

.authentication-layout-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    //background-color: rgba(var(--primary-rgb),1);
    background-color: var(--ui-primary);
    position: relative;
    overflow: auto;

    
    .form-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        gap: 50px;
        position: relative;

        .logo-title {
            width: 100px;
            height: auto;
            @include media-breakpoint-down(lg) {
                width: 100px;
            }
            @include media-breakpoint-down(md) {
                width: 100px;
            }
            @include media-breakpoint-down(sm) {
                width: 100px;
            }
        }

        small {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 10px;
            color: var(--ui-white);
            font-weight: 400;
            width: 100%;
            text-align: center;
        }

    }
}