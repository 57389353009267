@import "@/index.scss";

.c-dashboard-list-container {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: var(--ui-white);
  box-shadow: 0px 0px 30px #ececec;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 20px;

  .title-part {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #ededed;

    .title {
      font-weight: 400;
      color: var(--ui-gray);
      font-size: 1rem;
    }

    button{
        margin-left: auto;
        width: fit-content;
        height: fit-content;
        font-size: x-small;
    }
  }
  .recent-list-part {
    height: 300px;
    overflow: auto;
  }
}
