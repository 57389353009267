@import "@/index.scss";


.l-profile {
    width: 100%;
    min-height: 100vh;
    position: relative;

    .backdrop {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(var(--ui-dark-rgb),0.5);
      width: 100%;
      height: 100%;
      z-index: 2;

      @include media-breakpoint-down(lg) { 
        display: none;
        &.showed {
          display: block;
        }
      }

    


    }
  
    .profile-content {
      margin-left: 330px;
      padding: 0 40px;
      width: calc( 100% - 330px ) ;
      height: 100vh;
      background: var(--ui-light);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: relative;
      z-index: 1;
      gap: 10px;

      &::before {
        content: '';
        background: #ffffff;
        height: 70px;
        width: 100%;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        box-shadow: 0px 0px 20px #dcdcdc;
      }

      @include media-breakpoint-down(lg) { 
        margin-left: unset;
        padding: 0 20px;
        width: 100%;
      }

      @include media-breakpoint-down(xl) { 
        padding: 0 20px;
      }
    }
  }
  